<template>
  <v-container fluid>
    <v-tab-item
      class="pb-12"
      eager
    >
      <validation-observer v-slot="{ valid }">
        <validation-helper
          :interceptor="valid"
          @is-valid="$emit('step-observer', { step: 0, complete: $event })"
        />
        <div class="text-center display-1 font-weight-normal mb-6">
          Vamos começar preenchendo as informações básicas
        </div>
        <v-row class="mx-auto">
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required|max:255"
            >
              <v-text-field
                class="my-0"
                v-model="titulo"
                id="titulo-conciliacao"
                label="Título"
                outlined
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="mx-auto">
          <v-col cols="11">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              id="flexibilidade-conciliacao"
            >
              <v-select
                class="my-0"
                v-model="flexibilidade"
                :items="['Flexível', 'Rígida', 'Parcial']"
                label="Flexibilidade"
                outlined
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col
            cols="1"
            class="pl-1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="pt-4"
                  id="dialog-flexibilidade-conciliacao"
                  size="28"
                  v-bind="attrs"
                  v-on="on"
                  @click.close="dialogFlexibilidade = true"
                >
                  mdi-progress-question
                </v-icon>
              </template>
              <span
                >Clique aqui para obter um informativo sobre as diferenças das
                flexibilidades</span
              >
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row class="mx-auto">
          <v-col cols="12">
            <v-expansion-panels
              v-model="panels"
              accordion
            >
              <v-expansion-panel id="conciliacao-editar-rodadas">
                <v-expansion-panel-header
                  style="font-weight: 300; font-size: 15px"
                >
                  Editar Rodadas
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  class="expansion-panel-padding-x-clear"
                >
                  <rodadas-conciliacoes
                    v-model="rodadas"
                    :atributosList="atributosList"
                    :tipoConciliacao="tipoConciliacao"
                    atributosCols="12"
                    atributosSm="2"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <v-row class="mx-auto">
          <v-col
            cols="12"
            class="pl-2"
            id="conciliacao-atributos-coringa"
          >
            <div class="d-inline-flex">
              <v-checkbox
                class="my-0"
                v-model="atributosCoringa"
                label="Habilitar Atributos Coringa?"
              />
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-1 mb-5"
                    id="conciliacao-atributos-coringa-help"
                    v-bind="attrs"
                    v-on="on"
                    @click="dialogAtributosCoringa = true"
                  >
                    mdi-progress-question
                  </v-icon>
                </template>
                <span
                  >Clique aqui para obter um informativo sobre habilitar
                  atributos coringa</span
                >
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </validation-observer>
    </v-tab-item>
    <template v-for="(item, key) in partidaReferencia">
      <v-tab-item
        :class="`pb-12 tab-${key}-conciliacao`"
        :key="key"
        eager
      >
        <validation-observer v-slot="{ valid }">
          <validation-helper
            :interceptor="valid"
            @is-valid="
              $emit('step-observer', {
                step: key === 'partida' ? 1 : 2,
                complete: $event
              })
            "
          />
          <div :id="`conciliacao-base-de-${key}`">
            <v-row
              align="center"
              class="mx-auto"
            >
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :id="`selecionar-origem-${key}-conciliacao`"
                >
                  <v-select
                    v-model="item.origem"
                    :items="['ARQUIVO', 'RELATORIO']"
                    :label="
                      key == 'partida'
                        ? 'Origem Base de Partida'
                        : 'Origem Base de Referência'
                    "
                    outlined
                    @change="clearInputsOrigem(item.origem)"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row
              align="center"
              class="mx-auto"
            >
              <v-col
                cols="12"
                class="mb-4"
                v-show="item.origem"
              >
                <v-alert
                  dense
                  outlined
                  type="info"
                  color="blue"
                  class="pa-2"
                >
                  <div v-if="item.origem == 'RELATORIO'">
                    O Relatório selecionado deve conter os campos <b>ID</b> e
                    <b>QTD</b>. <br />Obs: O campo <b>QTD</b> pode conter os
                    nome <b>QTD Somatório</b>, <b>QTD MCPSE</b> ou
                    <b>QTD</b> para ser validado. <br />Obs²: O campo
                    <b>ID</b> pode conter os nomes <b>ID</b>, <b>COD_ID</b>,
                    <b>IMOBILIZADO</b> ou <b>SUBNÚMERO</b> para ser validado.
                  </div>

                  <div v-if="item.origem == 'ARQUIVO'">
                    O arquivo selecionado deve conter os campos <b>ID</b> e
                    <b>QTD</b>. <br />Obs: O campo <b>QTD</b> pode conter os
                    nome <b>QTD Somatório</b>, <b>QTD MCPSE</b> ou
                    <b>QTD</b> para ser validado. <br />Obs²: O campo
                    <b>ID</b> pode conter os nomes <b>ID</b>, <b>COD_ID</b>,
                    <b>IMOBILIZADO</b>, <b>EI</b> ou <b>SUBNÚMERO</b> para ser
                    validado.
                    <link-download-arquivo-modelo
                      :tipoConciliacao="tipoConciliacao"
                    />
                  </div>
                </v-alert>
              </v-col>
            </v-row>
            <v-row
              align="center"
              class="mx-auto"
              v-if="item.origem == 'RELATORIO'"
            >
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                >
                  <v-text-field
                    v-model="item.relatorioNome"
                    :id="`selecionar-relatorio-${key}-conciliacao`"
                    label="Relatorio"
                    placeholder="Selecione uma relatório"
                    readonly
                    outlined
                    :success="item.headerValido && item.relatorioNome != ''"
                    @focus.close="dialogRelatorio = true"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <validation-provider rules="required">
                <input
                  type="hidden"
                  :name="`valida-header-relatorio-${key}`"
                  :value="item.headerValido ? 'OK' : ''"
                />
              </validation-provider>
            </v-row>
            <v-row
              align="center"
              class="mx-auto"
              v-if="item.origem == 'ARQUIVO'"
            >
              <v-col
                cols="12"
                class="pl-2"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :id="`selecionar-arquivo-${key}-conciliacao`"
                >
                  <v-file-input
                    @change="uploadNewFile(item)"
                    label="Arquivo CSV"
                    placeholder="Selecione um arquivo CSV"
                    outlined
                    accept=".csv, .zip"
                    v-model="item.arquivo"
                    :show-size="!item.fakeFile"
                    truncate-length="30"
                    :loading="item.uploading"
                    loader-height="4"
                    :success="
                      (item.arquivoId && item.arquivo && item.headerValido) ||
                      item.fakeFile
                        ? true
                        : false
                    "
                    :error-messages="errors"
                  />
                </validation-provider>
                <validation-provider rules="required">
                  <input
                    type="hidden"
                    :name="`valida-upload-${key}`"
                    :value="
                      (item.arquivoId && item.headerValido) || item.fakeFile
                        ? 'OK'
                        : ''
                    "
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <parametros-arquivo-csv
              v-if="item.origem == 'ARQUIVO'"
              :id="`campos-arquivo-${key}-conciliacao`"
              :delimitador.sync="item.delimitador"
              :involucro.sync="item.involucro"
              :escape.sync="item.escape"
            />
          </div>
          <v-row
            align="center"
            class="mx-auto"
          >
            <v-col cols="12">
              <v-expansion-panels
                v-model="item.panels"
                accordion
              >
                <v-expansion-panel :id="`conciliacao-editar-mapeamento-${key}`">
                  <v-expansion-panel-header
                    style="font-weight: 300; font-size: 15px"
                  >
                    Editar Mapeamento
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr
                            v-for="(value, coluna) in item.mapeamento"
                            :key="coluna"
                          >
                            <td>
                              <strong class="d-inline-flex"
                                >{{ coluna | replaceUnderscoresWithSpace }}
                                <v-icon
                                  size="9"
                                  class="pl-1 mb-1"
                                  color="error"
                                  v-if="
                                    ['ID', 'QTD', ...prioridades].includes(
                                      coluna
                                    )
                                  "
                                >
                                  mdi-asterisk
                                </v-icon>
                              </strong>
                            </td>
                            <td style="max-width: 125px">
                              <validation-provider
                                v-slot="{ errors }"
                                :rules="
                                  ['ID', 'QTD', ...prioridades].includes(coluna)
                                    ? 'required'
                                    : undefined
                                "
                              >
                                <v-select
                                  v-model="item.mapeamento[coluna]"
                                  :items="item.header"
                                  clearable
                                  dense
                                  no-data-text="Nenhum campo disponível."
                                  :hide-details="errors.length ? false : true"
                                  :error="
                                    ['ID', 'QTD', ...prioridades].includes(
                                      coluna
                                    ) && !item.mapeamento[coluna]
                                  "
                                  :error-messages="errors"
                                />
                              </validation-provider>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </validation-observer>
      </v-tab-item>
    </template>
    <dialog-flexibilidade :activated.sync="dialogFlexibilidade" />
    <dialog-atributos-coringa :activated.sync="dialogAtributosCoringa" />
    <dialog-seletor-relatorio
      :activated.sync="dialogRelatorio"
      @selected="setRelatorio($event)"
    />
  </v-container>
</template>

<script>
import conciliacoesWizardTabsMixins from '@/mixins/conciliacoes/conciliacoesWizardTabsMixins.js';

export default {
  mixins: [conciliacoesWizardTabsMixins],

  components: {
    RodadasConciliacoes: () =>
      import('@/components/power-ups/conciliacoes/RodadasConciliacoes.vue'),
    DialogFlexibilidade: () =>
      import('@/components/power-ups/conciliacoes/DialogFlexibilidade.vue'),
    DialogAtributosCoringa: () =>
      import('@/components/power-ups/conciliacoes/DialogAtributosCoringa.vue'),
    DialogSeletorRelatorio: () =>
      import('@/components/general/seletores/DialogSeletorRelatorio.vue'),
    ValidationHelper: () => import('@/components/general/ValidationHelper.vue'),
    ParametrosArquivoCsv: () =>
      import('@/components/general/GeneralParametrosArquivoCsv.vue'),
    LinkDownloadArquivoModelo: () =>
      import(
        '@/components/power-ups/conciliacoes/LinkDownloadArquivoModelo.vue'
      )
  },

  created() {
    if (this.$route.params.id) this.fillFields();
  },

  data: () => ({
    tipoConciliacao: 'CUSTOM',
    atributosList: [
      'TUC',
      'ODI',
      'TI',
      'A1',
      'A2',
      'A3',
      'A4',
      'A5',
      'A6',
      'UAR',
      'IDUC',
      'Inventario',
      'Obra',
      'Material',
      'Data',
      'Generico_1',
      'Generico_2',
      'Generico_3'
    ],
    panels: 0,
    precisaOrganizarPrioridades: false
  }),

  methods: {
    organizarPrioridades() {
      if (this.precisaOrganizarPrioridades) {
        let atributos = [];
        this.rodadas.forEach(
          (arr) => (atributos = atributos.concat(atributos, arr.valores))
        );
        this.prioridades = Array.from(new Set(atributos));
      }

      this.precisaOrganizarPrioridades = false;
    }
  },

  watch: {
    rodadas() {
      this.precisaOrganizarPrioridades = true;
    },
    tab(newTab) {
      if (newTab !== 0 && this.precisaOrganizarPrioridades) {
        this.organizarPrioridades();
      }
    }
  }
};
</script>

<style>
.ghost {
  opacity: 0.5;
}
</style>
