import FilesService from '@/services/FilesService.js';
import ConciliacoesService from '@/services/ConciliacoesService.js';
import randomString from '@/utils/generateRandomString';
import tourMixins from '@/mixins/tourMixins';

export default {
  mixins: [tourMixins],

  props: {
    conciliacao: {
      type: Object,
      default: () => {}
    },
    tab: {
      type: Number,
      required: true
    }
  },

  mounted() {
    this.iniciarTourPrimeiraTab();
  },

  data() {
    return {
      titulo: '',
      tucsList: [
        '125',
        '135',
        '160',
        '190',
        '210',
        '255',
        '295',
        '330',
        '340',
        '345',
        '395',
        '565',
        '570',
        '575',
        '580'
      ],
      atributosList: ['ODI', 'TI', 'A1', 'A2', 'A3', 'A4', 'A5', 'A6', 'Data'],
      prioridades: ['TUC'],
      rodadas: [{ key: '1', valores: ['TUC'] }],
      tucSelecionada: '',
      flexibilidade: 'Flexível',
      atributosCoringa: false,
      partidaReferencia: {
        partida: this.getDefaultPartidaReferencia(),
        referencia: this.getDefaultPartidaReferencia()
      },
      panels: undefined,
      dialogFlexibilidade: false,
      dialogAtributosCoringa: false,
      dialogRelatorio: false,
      arrTourPrimeiraTab: [
        {
          element: '#titulo-conciliacao',
          intro: 'Aqui você deve inserir um nome para sua conciliação.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#tuc-selecionada-conciliacao',
          intro: 'Aqui você deve selecionar uma TUC para sua conciliação.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#flexibilidade-conciliacao',
          intro:
            'Aqui você deve selecionar a flexibilidade da sua conciliação.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#dialog-flexibilidade-conciliacao',
          intro:
            'Você pode clicar aqui para obter mais informações sobre o impacto da flexibilidade na sua conciliação.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#conciliacao-editar-prioridades',
          intro:
            'Aqui você pode selecionar e alterar a ordem de Prioridade da sua conciliação, que é responsavel por gerar as rodadas.<br>Dica: você pode clicar e arrastar para alterar a ordem.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#conciliacao-editar-rodadas',
          intro:
            'Aqui você pode gerenciar as rodadas da sua conciliação.<br>Dica: você pode clicar e arrastar para alterar a ordem das rodadas.',
          scrollTo: 'tooltip',
          position: 'right'
        },
        {
          element: '#conciliacao-atributos-coringa',
          intro:
            'Aqui você pode escolher se sua conciliação habilitará Atributos Coringa.<br>Caso habilitado, irá considerar atributos vazios/invalidados ou iguais nas duas bases, caso contrário, apenas iguais em ambas as bases',
          scrollTo: 'tooltip',
          position: 'left'
        },
        {
          element: '#conciliacao-atributos-coringa-help',
          intro:
            'Caso tenha dúvidas sobre os Atributos Coringa, clique aqui pode clicar aqui para maiores detalhes.',
          scrollTo: 'tooltip',
          position: 'left'
        }
      ],
      arrTourSegundaTab: [
        {
          element: '#conciliacao-base-de-partida',
          intro:
            'Aqui você deve preencher as informações da base de dados de <b>Partida</b> da sua conciliação.',
          scrollTo: 'tooltip'
        },
        {
          element: '#conciliacao-editar-mapeamento-partida',
          intro:
            'Aqui você deve mapear a base <b>Partida</b>. Os atributos selecionados nas rodadas são obrigatórios.',
          scrollTo: 'tooltip'
        }
      ],
      arrTourTerceiraTab: [
        {
          element: '#conciliacao-base-de-referencia',
          intro:
            'Aqui você deve preencher as informações da base de dados de <b>Referência</b> da sua conciliação.',
          scrollTo: 'tooltip'
        },
        {
          element: '#conciliacao-editar-mapeamento-referencia',
          intro:
            'Aqui você deve mapear a base <b>Referência</b>. Os atributos selecionados nas rodadas são obrigatórios.',
          scrollTo: 'tooltip'
        },
        {
          element: '#btn-save-conciliacao',
          intro:
            'Após seleção dos critérios anteriores clique aqui para salvar e executar sua conciliação.',
          scrollTo: 'tooltip',
          position: 'left'
        }
      ]
    };
  },

  methods: {
    getDefaultPartidaReferencia() {
      let obj = {
        origem: 'ARQUIVO',
        relatorioId: '',
        relatorioNome: '',
        arquivo: undefined,
        arquivoId: '',
        headerValido: false,
        uploading: false,
        delimitador: ';',
        involucro: `"`,
        escape: `"`,
        fakeFile: false
      };

      if (this.tipoConciliacao == 'CUSTOM') {
        obj.header = [];
        obj.panels = undefined;
        obj.mapeamento = this.getDefaultMapeamento();
      }

      return obj;
    },

    getDefaultMapeamento() {
      return {
        ID: '',
        QTD: '',
        TUC: '',
        ODI: '',
        TI: '',
        A1: '',
        A2: '',
        A3: '',
        A4: '',
        A5: '',
        A6: '',
        UAR: '',
        IDUC: '',
        Inventario: '',
        Obra: '',
        Material: '',
        Data: '',
        Generico_1: '',
        Generico_2: '',
        Generico_3: ''
      };
    },

    uploadNewFile(item) {
      if (!item.arquivo) return;

      item.fakeFile = false;
      item.arquivoId = '';
      item.uploading = true;

      let formDataWithFile = new FormData();
      formDataWithFile.append('arquivo', item.arquivo);

      FilesService.upload(formDataWithFile, {})
        .then((response) => {
          const arquivo = response.data.arquivos[0];
          item.arquivoId = arquivo.id;
          this.validarHeader(arquivo.id, item);
        })
        .catch((err) => {
          console.error(err);

          if (err?.data?.cod_erro == 10000) {
            let msg =
              'Há mais de um arquido dentro do ZIP.<br>Adicione apenas um arquivo CSV.';
            this.$toast.error(msg, '', { position: 'topRight', timeout: 8000 });
          } else {
            this.$toast.error('Erro no upload do arquivo.', '', {
              position: 'topRight'
            });
          }

          item.arquivo = undefined;
        })
        .finally(() => {
          item.uploading = false;
        });
    },

    setRelatorio(event) {
      const key = this.tab == 1 ? 'partida' : 'referencia';

      this.validarHeader(event.id, this.partidaReferencia[key]);

      this.partidaReferencia[key].relatorioId = event.id;
      this.partidaReferencia[
        key
      ].relatorioNome = `#${event.id} - ${event.nome}`;

      this.dialogRelatorio = false;
    },

    validarHeader(id, item) {
      const postData = {
        tipoConciliacao: this.tipoConciliacao,
        origem: item.origem,
        delimitador: item.delimitador,
        involucro: item.involucro,
        escape: item.escape,
        base: this.tab == 1 ? 'partida' : 'referência'
      };

      ConciliacoesService.validaHeader(id, postData)
        .then((res) => {
          item.headerValido = true;
          if (this.tipoConciliacao == 'CUSTOM') {
            item.mapeamento = this.getDefaultMapeamento();
            item.header = res.data.header;
            item.panels = 0;
            item.header.forEach((col) => {
              if (item.mapeamento[col] != undefined) item.mapeamento[col] = col;
            });
          }
        })
        .catch((err) => {
          let msg = '';
          if (
            err &&
            err.data &&
            (err.data.error.includes(
              'não contém todas as colunas necessárias. Falta:'
            ) ||
              err.data.error.includes(
                'Verifique se o delimitador do arquivo está correto'
              ))
          ) {
            msg = err.data.error;
          } else {
            msg = `Erro ao verificar colunas do(a) ${item.origem}.`;
          }

          this.$toast.error(msg, '', { position: 'topRight', timeout: 5000 });
          console.error(err);

          item.headerValido = false;
          item.arquivo = undefined;
          item.arquivoId = '';
          item.relatorioNome = '';
          item.relatorioId = '';
        });
    },

    fillFields() {
      let item = this.conciliacao;
      item.parametros = JSON.parse(item.parametros);

      this.titulo = item.nome;
      this.flexibilidade = item.flexibilidade;
      this.rodadas = item.parametros?.rodadas
        ? item.parametros.rodadas.map((rodada) => {
            return { key: randomString(20), valores: rodada };
          })
        : [{ key: randomString(20), valores: ['TUC'] }];

      this.atributosCoringa = item.parametros?.vazio == 'DIFNAN' ? true : false;

      this.partidaReferencia = {
        partida: {
          origem: item.origem_dados_contabil,
          relatorioId:
            item.origem_dados_contabil == 'RELATORIO'
              ? item.relatorio_contabil.id
              : null,
          relatorioNome:
            item.origem_dados_contabil == 'RELATORIO'
              ? item.relatorio_contabil.nome
              : '',
          arquivo:
            item.origem_dados_contabil == 'ARQUIVO'
              ? this.getFakeFileForValidation(item.arquivo_contabil.arquivo)
              : undefined,
          arquivoId: item.arquivo_contabil_id,
          headerValido: true,
          uploading: false,
          delimitador: item.arquivo_contabil_delimitador,
          involucro: item.arquivo_contabil_involucro,
          escape: item.arquivo_contabil_escape,
          fakeFile: item.origem_dados_contabil == 'ARQUIVO' ? true : false
        },
        referencia: {
          origem: item.origem_dados_fisico,
          relatorioId:
            item.origem_dados_fisico == 'RELATORIO'
              ? item.relatorio_fisico.id
              : null,
          relatorioNome:
            item.origem_dados_fisico == 'RELATORIO'
              ? item.relatorio_fisico.nome
              : '',
          arquivo:
            item.origem_dados_fisico == 'ARQUIVO'
              ? this.getFakeFileForValidation(item.arquivo_fisico.arquivo)
              : undefined,
          arquivoId: item.arquivo_fisico_id,
          headerValido: true,
          uploading: false,
          delimitador: item.arquivo_fisico_delimitador,
          involucro: item.arquivo_fisico_involucro,
          escape: item.arquivo_fisico_escape,
          fakeFile: item.origem_dados_fisico == 'ARQUIVO' ? true : false
        }
      };

      if (this.tipoConciliacao == 'EXPRESS') {
        this.tucSelecionada = item.tuc_selecionada;
        this.prioridades = item.parametros.prioridadeBusca;
        this.atributosList = [
          ...this.prioridades.filter((atrib) => atrib != 'TUC'),
          ...this.atributosList.filter(
            (atrib) => !this.prioridades.includes(atrib)
          )
        ];
      } else {
        this.prioridades = item.parametros?.prioridades
          ? item.parametros.prioridades
          : [];
        this.partidaReferencia.partida.header = item.header_contabil;
        this.partidaReferencia.partida.panels = undefined;
        this.partidaReferencia.partida.mapeamento = JSON.parse(
          item.mapeamento_contabil
        );
        this.partidaReferencia.referencia.header = item.header_fisico;
        this.partidaReferencia.referencia.panels = undefined;
        this.partidaReferencia.referencia.mapeamento = JSON.parse(
          item.mapeamento_fisico
        );
      }
    },

    getFakeFileForValidation(nomeArquivo) {
      return new File([''], nomeArquivo, { type: 'text/csv' });
    },

    clearInputsOrigem(origem) {
      const key = this.tab == 1 ? 'partida' : 'referencia';
      this.partidaReferencia[key] = this.getDefaultPartidaReferencia();
      this.partidaReferencia[key].origem = origem;
    },

    iniciarTourPrimeiraTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: () => {
          this.$emit('update:tab', 1);
          this.iniciarTourSegundaTab();
        }
      };

      if (this.tipoConciliacao == 'EXPRESS') {
        this.panels = [0, 1];
      } else {
        this.arrTourPrimeiraTab.splice(1, 1);
        this.arrTourPrimeiraTab.splice(3, 1);
      }

      this.iniciarTour(this.arrTourPrimeiraTab, [], [], [onBeforeExitFunction]);
    },

    iniciarTourSegundaTab() {
      this.doneLabel = 'Próximo';
      this.desativarTourAoSair = false;

      let onBeforeExitFunction = {
        func: () => {
          this.$emit('update:tab', 2);
          this.iniciarTourTerceiraTab();
        }
      };

      if (this.tipoConciliacao == 'EXPRESS') {
        this.arrTourSegundaTab.splice(1, 1);
      } else {
        ['partida', 'referencia'].forEach((key) => {
          this.partidaReferencia[key].panels = 0;
        });
      }

      this.iniciarTour(this.arrTourSegundaTab, [], [], [onBeforeExitFunction]);
    },

    iniciarTourTerceiraTab() {
      this.doneLabel = 'Finalizar';
      this.desativarTourAoSair = true;

      let onBeforeExitFunction = {
        func: () => {
          this.$emit('update:tab', 0);
          this.panels = undefined;
        }
      };

      if (this.tipoConciliacao == 'EXPRESS') {
        this.arrTourTerceiraTab.splice(1, 1);
      } else {
        ['partida', 'referencia'].forEach((key) => {
          this.partidaReferencia[key].panels = 0;
        });
      }

      this.iniciarTour(this.arrTourTerceiraTab, [], [], [onBeforeExitFunction]);
    }
  }
};
